<template>
  <v-menu
    :close-on-content-click="true"
    :nudge-width="140"
    :nudge-bottom="10"
    offset-y
    z-index="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        :color="selected_img.length > 0 ? 'primary' :'blue-grey lighten-5'"
        :dark="selected_img.length > 0"
        class="ml-2 pr-1"
        style="height: 29px !important;overflow: visible !important"
        label
        link
        v-bind="attrs"
        v-on="on"
      >                          
      <span :class="{'font-weight-bold': selected_img.length > 0 }">Fotos</span>
      <span v-if="selected_img.length > 0">
        : {{ selected_img === '1' ? 'Con imágenes' : selected_img === '0' ? 'Sin imágenes' : '' }}
      </span>   
      <v-icon 
        size="22" 
        class="ml-1"
        v-text="false ? 'mdi-menu-up' : 'mdi-menu-down' "
      />          
      </v-chip>
    </template>
    <v-card>
      <v-card-text class="px-0">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-radio-group 
              v-model="selected_img"
              class="my-0"
              hide-details
            >
              <v-list
                dense
                class="py-0"
              >
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mr-4">
                      <v-radio                                 
                        color="icono" 
                        :input-value="active" 
                        value="1"
                      />
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Visita Con imágenes</v-list-item-title>                            
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-list-item>
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="mr-4">
                      <v-radio
                        color="icono" 
                        :input-value="active" 
                        value="0" 
                      />
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Visita sin imágenes</v-list-item-title>                            
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-radio-group>
            <v-divider />
            <v-btn
              class="ma-1 "
              color="blue-grey"
              plain
              @click="selected_img = ''"
            >
              <span>Borrar selección</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'BtnImagen',
  props:{
    value: String
  },
  data() {
    return {
      selected_img: this.value,
    }
  },
  watch:{
    value(val, oldVal) {
      if(val === oldVal) return

      this.selected_img = val;
    },
    selected_img(val, oldVal) {
      if(val === oldVal) return
      
      this.$emit('input', val);
    }
  }
}
</script>